body {
  margin: 0;
  padding: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

html, body, #root {
  height: 100%;
}

body {
  font-family: 'Raleway', sans-serif !important;
}

a:hover {
  text-decoration: none !important;
}

.cursor-pointer {
  cursor: pointer;
}

.freetext-answers-modal {
  max-width: 1000px !important;
}
header {
  /* height: 70px; */
  box-shadow: -1px -2px 39px 0px rgba(0,0,0,0.36);
  position: fixed;
  width: 100%;
  top: 0;
  left: 0;
  z-index: 1001;
}

.navbar {
  background-color: #ffffff;
  padding: 0 !important;
}

.nav-item {
  background-color: transparent !important;
}

.nav-link {
  margin-left: 1em;
  color: #777777;
}

.nav-link:hover {
  color: #000000;
}

.active-nav {
  font-weight: bold;
}

.header-container {
  background-color: #fff;
  width: 100%;
  box-shadow: -2px 5px 5px -1px rgba(0,0,0,0.12);
}

.requests {
  background-color: #fff;
  box-shadow: 0px 2px 5px 2px rgba(0,0,0,0.12);
}

.request-icon {
  -webkit-animation: shake 5s cubic-bezier(.36,.07,.19,.97) both infinite;
          animation: shake 5s cubic-bezier(.36,.07,.19,.97) both infinite;
  cursor: pointer;
}

.nav-notification-bell {
  border-radius: 100%;
  width: 40px;
  height: 40px;
  display: -webkit-flex;
  display: flex;
  -webkit-justify-content: center;
          justify-content: center;
  -webkit-align-items: center;
          align-items: center;
  margin-right: 1rem;
  margin-top: 0.5rem;
  float: right;
}

.qibbie-logo {
  background-image: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAACMAAAAgCAYAAACYTcH3AAAETElEQVRYhc3XeYjVVRQH8M/MmGVppTXTikYJjZUtZqgpFIVE2aoIZbSRUha0QERkRUFSFEW0oGUlQhsJkW3aQgul7ZsVxZBWaplYOplpqZP9ce6vd99v5r2ZpD888Hjn3HOX7+/cs122I2qY+83uPZk3EqdhBFrRjB2wGkvxDt7Cq/hrW4BMbG3Xq46+Ny7CVBxRY05L+o3ENViDGbgLa/8roMYa4yOxGDPrAOmKBmAa2jDp/wAzGW/j4BprVuJzfIQfsLWLOXvicdyPpm0FMxWz6HR97+NiDMK+OBLH4IB08AQswN+ldZdjNhr+K5gxuK+kX4kzxbU9imVd7LEGz+BkHI0PS/rzhD/1GMyOmKPapG1p83mlNX1wSNINLOk+w2g8Vhq/FUN6CuZSHJiNL8OJwjIFjcJzaMdXKj6zPB02IM3bjAvxQra2N27rCZgGXFEan4IViW9IGy0UuaZ3ae7+IoIWJ8DQgQtEHirodBzUHZhRqq3yBl7J5Om4TvdOuB/mY3CS1+D2TN+A87sDM7Y09mDGj0hACvoMZ6C/iLjBuB5/Jv1uInoKmq06I5fP6gRmeCZ3qLbKNBWLfC6cs/CbDiwRVzhBJd+MSR9BZOFF2X7DdU4bVWAGZ/J3Kml8l9KX3IgNNfZ5SdSmgsZl/AcZv4M6ftOIvTN5aca3YqdMfrPWJolyMK0Z/31pXnM9MH0zeX3G98v4DvzeDZhVGb9zxq8rzdutHpjNmZzfZ75Jk0iM9WjXjP+jzrz1JfnfdY2qS31LxpeL4OHdgDk04/OyUbjBOlwr8lVx9pWy5NiIr0sbFiXhV3yR6c6pA6QXTsrk3H824BKRHO/EFlHrFuEeWQA1qg69fjg2k+dm/KUqIVumEcL53xM1bkGmm4mHhM8dgifTmcVee4ko06hzIZyc8feq1Kc+eFF12Ba0UGTyUaIubcl0TTgFzwpLn606m78u+WojPsanmXISDkv8OpyLTUneQ9zxJ7gqfV1Xjj0Q40ULuix9xBmqW5Zv0pyx2Eglem5JyIuxOSKTbhS16kw8gaJ7Pyr9ClotrqFJBEGfLgAW9CnuwNNKzViBdJ7IogUNEz1JUaHnC2vN0HUWbhbFdlANIL/gYeG4w/BUBuQsqWvIzXaxSttAmPA17JPkH3FZOnCqcO4lKldY0NY093nchONEeE8R7SvhM+PwrugST6Dzu2moSPsDsrG1uCF9WfnggvoLK24SCa/WvBbhg1NUd35fTmxtH1puyL/A8aot1B8PiNC9WXVyywGvSv9lIENwNV5O+96tcwvaQu0XZbNowE/tSomfRDX+VjjvWpHm+wmrDhQ+drg6tUj0QY9g+sTW9pW1eovVosUcr+tmel8RYdtKy8WTaBZ+LgbrPW8J53o2AZsickK5B+4ptYnGba54m5ffWN2CkRbNS7++wvNHi2sYJCKlr0h+G/GbsGybSGxfpsNXlDferukfebLzY0Me6jgAAAAASUVORK5CYII=);
  background-position: center;
  background-size: contain;
  background-repeat: no-repeat;
  height: 35px;
  width: 35px;
  margin-left: 1.5rem;
}

.logout-button {
  margin: 0.5rem 1rem 0.5rem 0;
}

@-webkit-keyframes shake {
  2%, 8% {
    -webkit-transform: translate3d(-2px, 0, 0);
            transform: translate3d(-2px, 0, 0);
  }
  
  4%, 10% {
    -webkit-transform: translate3d(2px, 0, 0);
            transform: translate3d(2px, 0, 0);
  }

 6%, 12% {
    -webkit-transform: translate3d(0px, 0, 0);
            transform: translate3d(0px, 0, 0);
  }

}

@keyframes shake {
  2%, 8% {
    -webkit-transform: translate3d(-2px, 0, 0);
            transform: translate3d(-2px, 0, 0);
  }
  
  4%, 10% {
    -webkit-transform: translate3d(2px, 0, 0);
            transform: translate3d(2px, 0, 0);
  }

 6%, 12% {
    -webkit-transform: translate3d(0px, 0, 0);
            transform: translate3d(0px, 0, 0);
  }

}
.sidebar {
  position: fixed !important;
  top: 0;
  bottom: 0;
  left: 0;
  z-index: 100;
  padding: 65px 0 15px 0 !important;
  box-shadow: 0px 3px 10px #CACACA;
  background-color: #749db4;
  width: 23rem;
  color:#fff;
  -webkit-transition: width 0.1s ease-in-out;
  transition: width 0.1s ease-in-out;
}

.sidebar.closed {
  width: 5.5rem !important;
  text-align: center;
}

#sidebar-container {
  position: relative;
  overflow: auto;
  height: 90%;
  margin-top: 2em;
}

.nav-tabs {
  border: none !important;
}

.side-nav-item {
  background-color: #173446;
  border: none !important;
  color: #efefef !important;
}

.sidebar-icon-tab {
  background-color: #749db4 !important;
  font-size: 2em;
}

.sidebar-icon-tab.active {
  color: #CCC !important;
}

.sidebar-toggler-icon {
  font-size: 2em; 
  color: #FFF;
  padding-top: 10px;
  padding-bottom:10px;
  cursor: pointer;
}

.nav-item .active {
  background-color: transparent !important;
}

.nav-link {
  margin-left: 0 !important;
  cursor: pointer;
  border: none !important;
  border-radius: 0 !important;
}

.tree-header {
  border: none;
  background-color: transparent;
  color: #fff;
  border-bottom: 2px solid #fff;
  margin-top: 1em;
  font-size: 1.2em;
}

.tree-child {
  margin-left: 0.5em;
  padding-left: 1em;
  min-height: 1em;
  /* border-left: 2px solid #CACACA; */
}

.tree-node {
  border-left: 2px solid #fff;
  color: #fff;
  line-height: 1.3em;
  padding: 0.5em;
}

.tree-node:hover {
  opacity: 0.8;
}

.tree-node-active {
  font-weight: bold;
}

.bottom-row {
  margin-top: 4em;
}

.dragging-over {
  /* background-color: rgba(255,255,255,0.8); */
  opacity: 0.7;
  /* background-color: #CACACAd4; */
  border-radius: 0 10px 10px 0;
}

.user-row {
  margin-bottom: 0 !important;
  margin-top: 1em !important;
  border-bottom: 1px solid #707070;
}

#add-user-form {
  margin-top: 3em;
  margin-bottom: 3em;
}

.user-add-button {
  float: right;
  margin-top: 10px;
  cursor: pointer;
}

.surveytree-input-group {
  margin-bottom: 1em;
}

.surveytree-input {
  background-color: #f8f8f8 !important;
}

#close-sidebar-icon {
  position: absolute;
  top: 120px;
  right: 0px;
  cursor: pointer;
}

.survey-selected-mark {
  font-size: 20px;
  margin-left: 3px;
}

.DateRangePicker_picker {
  z-index: 5000 !important;
  top: 0px !important;
}
hr {
  border-top: 3px solid #777777 !important;
}

.mapping-main {
  padding: 70px 0 0 5em !important;
}

.mapping-main:last-child .survey-main-row {
  margin-bottom: 5em;
}

.modalTextarea {
  width:100%;
}

.notifImportant {
  color: red;
}

.large-balls-column {
  width: 150px;
  margin-right: 1em;
}

.medium-balls-column {
  width: 120px;
  margin-right: 1em;
}

.comparisonIcon {
  font-size: 9em;
  color: #A6C5D5;
}


.comparisonIconCol {
  display: -webkit-flex;
  display: flex;
  -webkit-align-items: center;
          align-items: center;
}

.ball-container {
  position: absolute;
  left: 0;
  z-index: 10;
}

.ball-container-comparison {
  left: 0;
  z-index: 10;
  padding-top: 20px;
}

.large-score-ball {
  border: 8px solid #ffffff;
  border-radius: 50%;
  width: 140px;
  height: 140px;
  text-align: center;
  padding-top: 30px;
  color: #ffffff;
  font-size: 2.5em;
}

.medium-score-ball {
  border: 7px solid #ffffff;
  border-radius: 50%;
  height: 120px;
  width: 120px;
  background-color: #FFCC00;
  text-align: center;
  padding-top: 25px;
  box-shadow: 0 0 0 2pt #FFCC00;
  color: #ffffff;
  font-size: 2em;
}

.medium-score-barball {
  border: 7px solid #ffffff;
  border-radius: 50%;
  height: 100px;
  width: 100px;
  background-color: #FFCC00;
  text-align: center;
  padding-top: 14px;
  box-shadow: 0 0 0 2pt #FFCC00;
  color: #ffffff;
  font-size: 2em;
}

.small-score-barball {
  border: 7px solid #ffffff;
  border-radius: 50%;
  height: 80px;
  width: 80px;
  background-color: #FFCC00;
  text-align: center;
  padding-top: 10px;
  box-shadow: 0 0 0 2pt #FFCC00;
  color: #ffffff;
  font-size: 1.4em;
}

.small-score-barball-positive {
  border: 7px solid #ffffff;
  border-radius: 50%;
  height: 80px;
  width: 80px;
  background-color: #FFCC00;
  text-align: center;
  padding-top: 5px;
  box-shadow: 0 0 0 2pt #FFCC00;
  color: #ffffff;
  font-size: 1.4em;
}

.small-score-barball-positive:before {
  content: "+";
  font-size: 1.4em;
} 

.addNote {
  cursor:pointer;
}

.barWrapper {
  width: 100%;
  display: -webkit-flex;
  display: flex;
  -webkit-align-items: center;
          align-items: center;
  position: relative;
}

.small-score-barball::first-letter {
  font-size: 120%;
}

.firstToolTip.row {
  margin-top: 10px;
  display: none;
  position: absolute;
  width: 300px;
  z-index: 5;
  border-radius: 0.25em;
}

.secondToolTip.row {
  margin-top: 10px;
  display: none;
  position: absolute;
  width: 300px;
  z-index: 5;
  border-radius: 0.25em;
}

.barReportBall:hover .secondToolTip {
  display: block;
}

.barReportBall:hover .firstToolTip {
  display: block;
}
.barWrapper-comparison {
  width: 100%;
  display: -webkit-flex;
  display: flex;
  -webkit-align-items: center;
          align-items: center;
  position: relative;
  padding-top:15px;
}

.sentBall {
  border: 3px solid #ffffff;
  border-radius: 50%;
  height: 40px;
  width: 40px;
  text-align: center;
  padding-top: 5px;
  font-size: 0.9em;
  display: inline-block;
}

.barReportBall {
  border: 3px solid #ffffff;
  border-radius: 50%;
  height: 40px;
  width: 40px;
  text-align: center;
  padding-top: 5px;
  color: #ffffff;
  font-size: 0.9em;
  display: inline-block;
  margin-right: 10px;
  margin-top: 15px;
}
.small-score-ball {
  border: 5px solid #ffffff;
  border-radius: 50%;
  height: 90px;
  width: 90px;
  background-color: #BDE533;
  text-align: center;
  padding-top: 20px;
  box-shadow: 0 0 0 2pt #BDE533;
  color: #ffffff;
  font-size: 1.5em;
}

.small-analysis-ball {
  border: 3px solid #ffffff;
  border-radius: 50%;
  height: 40px;
  width: 40px;
  background-color: #BDE533;
  text-align: center;
  padding-top: 5px;
  color: #ffffff;
  font-size: 0.9em;
  display: inline-block;
  margin-right: 10px;
  margin-top: 15px;
}

.largest-score-analysis-ball {
  height: 47px !important;
  width: 47px !important;
  padding-top: 8px !important;
}

.report {
  padding-bottom:40px;
}

.survey-main-row {
  margin-left: 25px !important;
  position: relative;
}

.survey-title-row {
  padding-bottom: 1em;
}

.survey-title-container {
  padding-left: 100px !important;
  margin-left: 70px;
  padding-bottom: 25px;
}

.questiongroup-main-row {
  position: relative;
}

.questiongroup-main-row:first-child {
  padding-top: 0 !important;
}

.questiongroup-title-container {
  padding-left: 100px !important;
  margin-left: 60px;
  padding-bottom: 1em;
}

.questiongroup-title-container-comparison {
  padding-left: 100px !important;
  margin-left: 50px;
  padding-bottom: 1em;
}

.questiongroup-title-row {
  padding-bottom: 1em;
}

.question-main-row {
  position: relative;
}

.question-title-container {
  padding-left: 80px !important;
  margin-left: 45px;
  padding-bottom: 2em;
}

.question-title-container-comparison {
  padding-bottom: 2em;
  padding-top: 1.2em;
}

.super-border-left {
  border-left: 3px solid;
}

.business-input {
  border: none !important;
  border-bottom: 1px solid #707070 !important;
  border-radius: 0 !important;
}

.business-input:focus {
  border-color: #B5E413 !important;
  box-shadow: none !important;
}

.logo-group {
  margin: 1em 0 1em 0;
}

.logo-container {
  height: 150px;
  width: 150px;
  border: 1px solid #707070;
  margin-top: 1em;
  background-size: contain;
  background-repeat: no-repeat;
  margin: auto;
  border: 0px;
}

.dashboard-row {
  padding: 0 5em 0 5em;
  display: -webkit-flex;
  display: flex;
  -webkit-justify-content: center;
          justify-content: center;
  margin-top: 3em;
}

.dashboard-container {
  box-shadow: 0 2px 6px #d2d2d2;
}

.dashboard-box-background {
  background-image: url(/static/media/background.01a5d36b.png);
  background-size: cover;
  background-repeat: no-repeat;
  height: 300px;
}

.dashboard-box-content {
  padding: 2em 1em 2em 1em;
  background-color: #ffffff;
}

.dashboard-inner-row {
  -webkit-justify-content: center;
          justify-content: center;
}

.dashboard-small-box-background {
  height: 200px;
  background-color: #707070;
  box-shadow: 0 -2px 6px #d2d2d2;
}

.comparisonTopic {
  color: #FFF;
  font-size: 1.2em;
  padding-left: 15px;
  padding-top: 15px;
  font-weight: bold;
}

.dashboard-small-box-background-third {
  height: 200px;
  background-color: #2E617C;
  box-shadow: 0 -2px 6px #d2d2d2;
  display: -webkit-flex;
  display: flex;
  -webkit-justify-content: center;
          justify-content: center;
  font-size: 7em;
  color: #fff;
  font-weight: bold;
}

.dashboard-small-box {
  padding: 1em 10px 1em 10px;
  box-shadow: 0 2px 6px #d2d2d2;
  background-color: #ffffff;
  height: 175px;
}

.general-button {
  background-color: #fff !important;
  border: 0px !important;
  color: #777 !important;
  font-size: 1.4em !important;
  box-shadow: 0 3px 5px 0 rgba(0,0,0,.10) !important;
}

.general-button-report {
  background-color: #fff !important;
  color: #777 !important;
  font-size: 1.4em !important;
  box-shadow: 0 3px 5px 0 rgba(0,0,0,.10) !important;
  width: 20%;
  margin-right: 5px;
  float: left;
  border-radius: 50px;
  text-align:center;
  padding-top: 5px;
  border: 0px !important;
  cursor: pointer;
}

.general-button-report:hover {
  color: #fff !important;
  background-color:#B5E413 !important;
  transition:0.1s ease;
  -moz-transition:0.1s ease;
  -webkit-transition:0.1s ease;
}
.general-button-report i{
  cursor: pointer;
}
.report-button-wrapper{
  margin-top: 25px;
}

.general-button-report-active {
  color: #fff !important;
  background-color:#B5E413 !important;
  transition:0.3s ease;
  -moz-transition:0.3s ease;
  -webkit-transition:0.3s ease;
}

.notes-row {
  margin-top: 1em;
}

@media (max-width: 1200px) {
  .dashboard-small-container {
    margin-top: 2em;
  }
}


.texteditor-container {
  border: 1px solid #ddd;
  padding: 1em;
}

.texteditor-container.readonly {
  border: none;
  margin-top: 1em;
  padding: 0;
}

.texteditor-container.answer-analytic-text {
  margin-top: 2em;
}

.DraftEditor-root {
  background: #fff;
}

.DraftEditor-editor {
  border-top: 1px solid #ddd;
  cursor: text;
  font-size: 16px;
  margin-top: 10px;
}

.DraftEditor-editor .public-DraftEditorPlaceholder-root,
.DraftEditor-editor .public-DraftEditor-content {
  margin: 0 -15px -15px;
  padding: 15px;
}

.DraftEditor-editor .public-DraftEditor-content {
  min-height: 100px;
}

.DraftEditor-hidePlaceholder .public-DraftEditorPlaceholder-root {
  display: none;
}

.DraftEditor-editor .DraftEditor-blockquote {
  border-left: 5px solid #eee;
  color: #666;
  font-family: 'Hoefler Text', 'Georgia', serif;
  font-style: italic;
  margin: 16px 0;
  padding: 10px 20px;
}

.DraftEditor-editor .public-DraftStyleDefault-pre {
  background-color: rgba(0, 0, 0, 0.05);
  font-family: 'Inconsolata', 'Menlo', 'Consolas', monospace;
  font-size: 16px;
  padding: 20px;
}

.DraftEditor-controls {
  font-family: 'Helvetica', sans-serif;
  font-size: 14px;
  margin-bottom: 5px;
  -webkit-user-select: none;
     -moz-user-select: none;
      -ms-user-select: none;
          user-select: none;
}

.DraftEditor-styleButton {
  color: #999;
  cursor: pointer;
  margin-right: 16px;
  padding: 2px 0;
  display: inline-block;
}

.DraftEditor-activeButton {
  color: #5890ff;
}


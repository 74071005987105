header {
  /* height: 70px; */
  -webkit-box-shadow: -1px -2px 39px 0px rgba(0,0,0,0.36);
  -moz-box-shadow: -1px -2px 39px 0px rgba(0,0,0,0.36);
  box-shadow: -1px -2px 39px 0px rgba(0,0,0,0.36);
  position: fixed;
  width: 100%;
  top: 0;
  left: 0;
  z-index: 1001;
}

.navbar {
  background-color: #ffffff;
  padding: 0 !important;
}

.nav-item {
  background-color: transparent !important;
}

.nav-link {
  margin-left: 1em;
  color: #777777;
}

.nav-link:hover {
  color: #000000;
}

.active-nav {
  font-weight: bold;
}

.header-container {
  background-color: #fff;
  width: 100%;
  -webkit-box-shadow: -2px 5px 5px -1px rgba(0,0,0,0.12);
  -moz-box-shadow: -2px 5px 5px -1px rgba(0,0,0,0.12);
  box-shadow: -2px 5px 5px -1px rgba(0,0,0,0.12);
}

.requests {
  background-color: #fff;
  -webkit-box-shadow: -2px 5px 5px -1px rgba(0,0,0,0.12);
  -moz-box-shadow: -2px 5px 5px -1px rgba(0,0,0,0.12);
  box-shadow: 0px 2px 5px 2px rgba(0,0,0,0.12);
}

.request-icon {
  animation: shake 5s cubic-bezier(.36,.07,.19,.97) both infinite;
  cursor: pointer;
}

.nav-notification-bell {
  border-radius: 100%;
  width: 40px;
  height: 40px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-right: 1rem;
  margin-top: 0.5rem;
  float: right;
}

.qibbie-logo {
  background-image: url('../../assets/images/qibbie_logo.png');
  background-position: center;
  background-size: contain;
  background-repeat: no-repeat;
  height: 35px;
  width: 35px;
  margin-left: 1.5rem;
}

.logout-button {
  margin: 0.5rem 1rem 0.5rem 0;
}

@keyframes shake {
  2%, 8% {
    transform: translate3d(-2px, 0, 0);
  }
  
  4%, 10% {
    transform: translate3d(2px, 0, 0);
  }

 6%, 12% {
    transform: translate3d(0px, 0, 0);
  }

}
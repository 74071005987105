html, body, #root {
  height: 100%;
}

body {
  font-family: 'Raleway', sans-serif !important;
}

a:hover {
  text-decoration: none !important;
}

.cursor-pointer {
  cursor: pointer;
}

.freetext-answers-modal {
  max-width: 1000px !important;
}
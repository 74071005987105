.sidebar {
  position: fixed !important;
  top: 0;
  bottom: 0;
  left: 0;
  z-index: 100;
  padding: 65px 0 15px 0 !important;
  box-shadow: 0px 3px 10px #CACACA;
  background-color: #749db4;
  width: 23rem;
  color:#fff;
  -webkit-transition: width 0.1s ease-in-out;
  -moz-transition: width 0.1s ease-in-out;
  -o-transition: width 0.1s ease-in-out;
  transition: width 0.1s ease-in-out;
}

.sidebar.closed {
  width: 5.5rem !important;
  text-align: center;
}

#sidebar-container {
  position: relative;
  overflow: auto;
  height: 90%;
  margin-top: 2em;
}

.nav-tabs {
  border: none !important;
}

.side-nav-item {
  background-color: #173446;
  border: none !important;
  color: #efefef !important;
}

.sidebar-icon-tab {
  background-color: #749db4 !important;
  font-size: 2em;
}

.sidebar-icon-tab.active {
  color: #CCC !important;
}

.sidebar-toggler-icon {
  font-size: 2em; 
  color: #FFF;
  padding-top: 10px;
  padding-bottom:10px;
  cursor: pointer;
}

.nav-item .active {
  background-color: transparent !important;
}

.nav-link {
  margin-left: 0 !important;
  cursor: pointer;
  border: none !important;
  border-radius: 0 !important;
}

.tree-header {
  border: none;
  background-color: transparent;
  color: #fff;
  border-bottom: 2px solid #fff;
  margin-top: 1em;
  font-size: 1.2em;
}

.tree-child {
  margin-left: 0.5em;
  padding-left: 1em;
  min-height: 1em;
  /* border-left: 2px solid #CACACA; */
}

.tree-node {
  border-left: 2px solid #fff;
  color: #fff;
  line-height: 1.3em;
  padding: 0.5em;
}

.tree-node:hover {
  opacity: 0.8;
}

.tree-node-active {
  font-weight: bold;
}

.bottom-row {
  margin-top: 4em;
}

.dragging-over {
  /* background-color: rgba(255,255,255,0.8); */
  opacity: 0.7;
  /* background-color: #CACACAd4; */
  border-radius: 0 10px 10px 0;
}

.user-row {
  margin-bottom: 0 !important;
  margin-top: 1em !important;
  border-bottom: 1px solid #707070;
}

#add-user-form {
  margin-top: 3em;
  margin-bottom: 3em;
}

.user-add-button {
  float: right;
  margin-top: 10px;
  cursor: pointer;
}

.surveytree-input-group {
  margin-bottom: 1em;
}

.surveytree-input {
  background-color: #f8f8f8 !important;
}

#close-sidebar-icon {
  position: absolute;
  top: 120px;
  right: 0px;
  cursor: pointer;
}

.survey-selected-mark {
  font-size: 20px;
  margin-left: 3px;
}

.DateRangePicker_picker {
  z-index: 5000 !important;
  top: 0px !important;
}
hr {
  border-top: 3px solid #777777 !important;
}

.mapping-main {
  padding: 70px 0 0 5em !important;
}

.mapping-main:last-child .survey-main-row {
  margin-bottom: 5em;
}

.modalTextarea {
  width:100%;
}

.notifImportant {
  color: red;
}

.large-balls-column {
  width: 150px;
  margin-right: 1em;
}

.medium-balls-column {
  width: 120px;
  margin-right: 1em;
}

.comparisonIcon {
  font-size: 9em;
  color: #A6C5D5;
}


.comparisonIconCol {
  display: flex;
  align-items: center;
}

.ball-container {
  position: absolute;
  left: 0;
  z-index: 10;
}

.ball-container-comparison {
  left: 0;
  z-index: 10;
  padding-top: 20px;
}

.large-score-ball {
  border: 8px solid #ffffff;
  border-radius: 50%;
  width: 140px;
  height: 140px;
  text-align: center;
  padding-top: 30px;
  color: #ffffff;
  font-size: 2.5em;
}

.medium-score-ball {
  border: 7px solid #ffffff;
  border-radius: 50%;
  height: 120px;
  width: 120px;
  background-color: #FFCC00;
  text-align: center;
  padding-top: 25px;
  box-shadow: 0 0 0 2pt #FFCC00;
  color: #ffffff;
  font-size: 2em;
}

.medium-score-barball {
  border: 7px solid #ffffff;
  border-radius: 50%;
  height: 100px;
  width: 100px;
  background-color: #FFCC00;
  text-align: center;
  padding-top: 14px;
  box-shadow: 0 0 0 2pt #FFCC00;
  color: #ffffff;
  font-size: 2em;
}

.small-score-barball {
  border: 7px solid #ffffff;
  border-radius: 50%;
  height: 80px;
  width: 80px;
  background-color: #FFCC00;
  text-align: center;
  padding-top: 10px;
  box-shadow: 0 0 0 2pt #FFCC00;
  color: #ffffff;
  font-size: 1.4em;
}

.small-score-barball-positive {
  border: 7px solid #ffffff;
  border-radius: 50%;
  height: 80px;
  width: 80px;
  background-color: #FFCC00;
  text-align: center;
  padding-top: 5px;
  box-shadow: 0 0 0 2pt #FFCC00;
  color: #ffffff;
  font-size: 1.4em;
}

.small-score-barball-positive:before {
  content: "+";
  font-size: 1.4em;
} 

.addNote {
  cursor:pointer;
}

.barWrapper {
  width: 100%;
  display: flex;
  align-items: center;
  position: relative;
}

.small-score-barball::first-letter {
  font-size: 120%;
}

.firstToolTip.row {
  margin-top: 10px;
  display: none;
  position: absolute;
  width: 300px;
  z-index: 5;
  border-radius: 0.25em;
}

.secondToolTip.row {
  margin-top: 10px;
  display: none;
  position: absolute;
  width: 300px;
  z-index: 5;
  border-radius: 0.25em;
}

.barReportBall:hover .secondToolTip {
  display: block;
}

.barReportBall:hover .firstToolTip {
  display: block;
}
.barWrapper-comparison {
  width: 100%;
  display: flex;
  align-items: center;
  position: relative;
  padding-top:15px;
}

.sentBall {
  border: 3px solid #ffffff;
  border-radius: 50%;
  height: 40px;
  width: 40px;
  text-align: center;
  padding-top: 5px;
  font-size: 0.9em;
  display: inline-block;
}

.barReportBall {
  border: 3px solid #ffffff;
  border-radius: 50%;
  height: 40px;
  width: 40px;
  text-align: center;
  padding-top: 5px;
  color: #ffffff;
  font-size: 0.9em;
  display: inline-block;
  margin-right: 10px;
  margin-top: 15px;
}
.small-score-ball {
  border: 5px solid #ffffff;
  border-radius: 50%;
  height: 90px;
  width: 90px;
  background-color: #BDE533;
  text-align: center;
  padding-top: 20px;
  box-shadow: 0 0 0 2pt #BDE533;
  color: #ffffff;
  font-size: 1.5em;
}

.small-analysis-ball {
  border: 3px solid #ffffff;
  border-radius: 50%;
  height: 40px;
  width: 40px;
  background-color: #BDE533;
  text-align: center;
  padding-top: 5px;
  color: #ffffff;
  font-size: 0.9em;
  display: inline-block;
  margin-right: 10px;
  margin-top: 15px;
}

.largest-score-analysis-ball {
  height: 47px !important;
  width: 47px !important;
  padding-top: 8px !important;
}

.report {
  padding-bottom:40px;
}

.survey-main-row {
  margin-left: 25px !important;
  position: relative;
}

.survey-title-row {
  padding-bottom: 1em;
}

.survey-title-container {
  padding-left: 100px !important;
  margin-left: 70px;
  padding-bottom: 25px;
}

.questiongroup-main-row {
  position: relative;
}

.questiongroup-main-row:first-child {
  padding-top: 0 !important;
}

.questiongroup-title-container {
  padding-left: 100px !important;
  margin-left: 60px;
  padding-bottom: 1em;
}

.questiongroup-title-container-comparison {
  padding-left: 100px !important;
  margin-left: 50px;
  padding-bottom: 1em;
}

.questiongroup-title-row {
  padding-bottom: 1em;
}

.question-main-row {
  position: relative;
}

.question-title-container {
  padding-left: 80px !important;
  margin-left: 45px;
  padding-bottom: 2em;
}

.question-title-container-comparison {
  padding-bottom: 2em;
  padding-top: 1.2em;
}

.super-border-left {
  border-left: 3px solid;
}

.business-input {
  border: none !important;
  border-bottom: 1px solid #707070 !important;
  border-radius: 0 !important;
}

.business-input:focus {
  border-color: #B5E413 !important;
  box-shadow: none !important;
}

.logo-group {
  margin: 1em 0 1em 0;
}

.logo-container {
  height: 150px;
  width: 150px;
  border: 1px solid #707070;
  margin-top: 1em;
  background-size: contain;
  background-repeat: no-repeat;
  margin: auto;
  border: 0px;
}

.dashboard-row {
  padding: 0 5em 0 5em;
  display: flex;
  justify-content: center;
  margin-top: 3em;
}

.dashboard-container {
  box-shadow: 0 2px 6px #d2d2d2;
}

.dashboard-box-background {
  background-image: url('../../assets/images/background.png');
  background-size: cover;
  background-repeat: no-repeat;
  height: 300px;
}

.dashboard-box-content {
  padding: 2em 1em 2em 1em;
  background-color: #ffffff;
}

.dashboard-inner-row {
  justify-content: center;
}

.dashboard-small-box-background {
  height: 200px;
  background-color: #707070;
  box-shadow: 0 -2px 6px #d2d2d2;
}

.comparisonTopic {
  color: #FFF;
  font-size: 1.2em;
  padding-left: 15px;
  padding-top: 15px;
  font-weight: bold;
}

.dashboard-small-box-background-third {
  height: 200px;
  background-color: #2E617C;
  box-shadow: 0 -2px 6px #d2d2d2;
  display: flex;
  justify-content: center;
  font-size: 7em;
  color: #fff;
  font-weight: bold;
}

.dashboard-small-box {
  padding: 1em 10px 1em 10px;
  box-shadow: 0 2px 6px #d2d2d2;
  background-color: #ffffff;
  height: 175px;
}

.general-button {
  background-color: #fff !important;
  border: 0px !important;
  color: #777 !important;
  font-size: 1.4em !important;
  box-shadow: 0 3px 5px 0 rgba(0,0,0,.10) !important;
}

.general-button-report {
  background-color: #fff !important;
  color: #777 !important;
  font-size: 1.4em !important;
  box-shadow: 0 3px 5px 0 rgba(0,0,0,.10) !important;
  width: 20%;
  margin-right: 5px;
  float: left;
  border-radius: 50px;
  text-align:center;
  padding-top: 5px;
  border: 0px !important;
  cursor: pointer;
}

.general-button-report:hover {
  color: #fff !important;
  background-color:#B5E413 !important;
  transition:0.1s ease;
  -moz-transition:0.1s ease;
  -webkit-transition:0.1s ease;
}
.general-button-report i{
  cursor: pointer;
}
.report-button-wrapper{
  margin-top: 25px;
}

.general-button-report-active {
  color: #fff !important;
  background-color:#B5E413 !important;
  transition:0.3s ease;
  -moz-transition:0.3s ease;
  -webkit-transition:0.3s ease;
}

.notes-row {
  margin-top: 1em;
}

@media (max-width: 1200px) {
  .dashboard-small-container {
    margin-top: 2em;
  }
}

